import TravelokaProject from "../images/traveloka-project.png";
import SmartMotorcycleAssistantProject from "../images/smart-motorcycle-assistant-project.png";
import BriLifeProject from "../images/bri-life-project.png";
import BestAnimeToWatchProject from "../images/best-anime-to-watch-project.png";
import MyAlQuranProject from "../images/my-alquran-android-project.png";
import PopupAnythingProject from "../images/popupanything-android-project.png";

const itemProjectData = [
  {
    img: PopupAnythingProject,
    title: "Popup Anything",
    descEn: "A fun and interactive way to customize your connected device experience. Popup Anything makes those Bluetooth connections a little more delightful. Whenever you connect to a chosen Bluetooth device, a charming popup animation will appear on your screen, complete with the device name and even pictures you've chosen!",
    descId: "Cara yang menyenangkan dan interaktif untuk menyesuaikan pengalaman perangkat yang terhubung. Popup Anything membuat koneksi Bluetooth Anda sedikit lebih menyenangkan. Setiap kali Anda terhubung ke perangkat Bluetooth pilihan, animasi popup yang menarik akan muncul di layar Anda, lengkap dengan nama perangkat dan bahkan gambar yang Anda pilih!",
    descJp: "楽しくインタラクティブな方法で、接続されたデバイスの体験をカスタマイズできます。Popup AnythingはBluetooth接続をさらに楽しいものにします。選択したBluetoothデバイスに接続するたびに、デバイス名や選んだ画像が付いた魅力的なポップアップアニメーションが画面に表示されます！",
    gitHubUrl: "",
    publishedUrl: "https://play.google.com/store/apps/details?id=com.raf.popupanything",
  },
  {
    img: MyAlQuranProject,
    title: "My AL Qur'an",
    descEn: "Experience the Holy Quran like never before with a native Android application built on Jetpack Compose, offering premium features, customizable settings, and an exceptional user interface with captivating animations.",
    descId: "Nikmati Al-Qur'an seperti belum pernah sebelumnya dengan aplikasi Android asli yang dibangun menggunakan Jetpack Compose, menawarkan fitur premium, pengaturan yang dapat disesuaikan, dan antarmuka pengguna yang luar biasa dengan animasi yang memukau.",
    descJp: "Jetpack Composeで構築されたネイティブAndroidアプリケーションで、これまでにないコーランの体験を提供します。プレミアム機能、カスタマイズ可能な設定、魅力的なアニメーションで優れたユーザーインターフェイスを提供します。",
    gitHubUrl: "",
    publishedUrl: "https://play.google.com/store/apps/details?id=com.heavenly.myalquran",
  },
  {
    img: SmartMotorcycleAssistantProject,
    title: "Smart Motorcycle Assistant",
    descEn: "A smart assistant to help with motorcycle needs.",
    descId: "Asisten pintar untuk membantu kebutuhan sepeda motor.",
    descJp: "オートバイのニーズをサポートするスマートアシスタント。",
    gitHubUrl: "",
    publishedUrl: "https://play.google.com/store/apps/details?id=com.raf.smartmotorcycleassistant&pli=1",
  },
  {
    img: BriLifeProject,
    title: "BRI Life - MyLife",
    descEn: "BRI Life insurance company.",
    descId: "Perusahaan asuransi BRI Life.",
    descJp: "BRIライフ保険会社。",
    gitHubUrl: "",
    publishedUrl: "",
  },
  {
    img: TravelokaProject,
    title: "Traveloka Hotel Ranking",
    descEn: "Hotel recommendations that match user behavior.",
    descId: "Rekomendasi hotel yang sesuai dengan perilaku pengguna.",
    descJp: "ユーザーの行動に基づいたホテルの推奨。",
    gitHubUrl: "https://github.com/Traveloka-Hotel-Ranking",
    publishedUrl: "",
  },
  {
    img: BestAnimeToWatchProject,
    title: "The Best Anime To Watch",
    descEn: "My first Android app developed using Jetpack Compose.",
    descId: "Aplikasi Android pertama saya yang dikembangkan menggunakan Jetpack Compose.",
    descJp: "Jetpack Composeを使用して開発した最初のAndroidアプリです。",
    gitHubUrl: "https://github.com/RidhwanAF/BestAnimeToWatch_MaterialDesign_JetpackCompose",
    publishedUrl: "",
  },
];

export default itemProjectData;
